import { Component, DestroyRef, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { LayersService } from '@core/services/layers.service';
import { PermissionsService } from '@core/services/permissions.service';
import { environment } from '@environments/environment';
import { ClientsService } from '@modules/clients/services/clients.service';
import { Stats } from '@shared/components/field-stats/field-stats.component';
import { Client } from '@shared/models/client';
import { removeDiacritics } from '@shared/utils/utils';
import _ from 'lodash';
import moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, filter, take, tap } from 'rxjs/operators';
import { ClientFormComponent } from '../client-form/client-form.component';

@Component({
    selector: 'clients-list',
    templateUrl: './clients-list.component.html',
    styleUrls: ['./clients-list.component.less'],
})
export class ClientsListComponent implements OnInit {
    columns = [
        {
            title: 'Denumire',
            isSortable: true,
            sortOrder: null,
            sortFn: (a: Client, b: Client) => a.name.localeCompare(b.name),
        },
        {
            title: 'Suprafață încărcată',
            isSortable: true,
            sortOrder: null,
            sortFn: (a, b) => a.total_area - b.total_area,
            width: '192px',
        },
        {
            title: 'Cod fiscal',
            isSortable: true,
            sortOrder: null,
            sortFn: (a: Client, b: Client) => a.fiscal_number.localeCompare(b.fiscal_number),
        },
        {
            title: 'Data încărcării',
            isSortable: true,
            sortOrder: 'descend',
            sortFn: (a: Client, b: Client) => {
                return !a.last_uploaded_at
                    ? 0
                    : (a.last_uploaded_at as string).localeCompare(b.last_uploaded_at as string);
            },
            width: '174px',
        },
        {
            title: 'Acțiuni',
            isSortable: false,
            width: '148px',
        },
    ];

    destroyRef = inject(DestroyRef);

    @ViewChild('clientForm') clientForm: ClientFormComponent;

    /**
     * The currently displayed client rows (may be filtered).
     * Unique by fiscal_number.
     */
    displayedClients: Client[] = [];

    /**
     * All client rows.
     * Unique by fiscal_number.
     */
    clients: Client[] = [];

    /**
     * All clients with duplicates
     */
    allSanitizedClients: Client[] = [];

    /**
     * All sanitized clients grouped by fiscal_number
     */
    groupedClients: _.Dictionary<Client[]>;
    totalArea = 0;

    /**
     * The client that is being edited
     */
    client: Client = null;

    expandedData: { [key: string]: boolean } = {};

    isAddingModalVisible = false;
    isEditingModalVisible = false;

    isSaving = false;
    isDeleting = false;

    /**
     * Used to highlight the current client
     */
    currentClientId = -1;

    searchValue: string | null;
    fieldStats: Stats = null;

    /**
     * Permissions
     */
    permissions = [];
    canModifyFields = false;

    onSearchChange = _.debounce((value: string) => this.onTextChanged(value), 400);

    private isLoggingEnabled = !environment.production;

    constructor(
        private clientsService: ClientsService,
        private message: NzMessageService,
        private modal: NzModalService,
        private router: Router,
        private permissionsService: PermissionsService,
        private layerService: LayersService,
    ) {}

    ngOnInit() {
        // Obtain permissions
        this.permissions = this.permissionsService.Permissions;
        this.canModifyFields = this.permissionsService.canModifyFields();
        const today = moment();

        this.clientsService.Clients.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
            (clients: Client[]) => {
                this.allSanitizedClients = _(clients)
                    .map((client: Client) => ({
                        ...client,
                        display_name: client.name,
                        name: removeDiacritics(client.name),
                        canReplaceFields: true,
                    }))
                    .orderBy(['moment'], ['desc'])
                    .value();

                const groupedClients: _.Dictionary<Client[]> = _.groupBy(
                    this.allSanitizedClients,
                    'fiscal_number',
                );

                _.forEach(Object.values(groupedClients), (clientsList: Client[]) => {
                    const clientsWithFields = _.filter(
                        clientsList,
                        (client) => !!client.agriculture_year,
                    );

                    if (!_.isEmpty(clientsWithFields)) {
                        const [lastYearUploaded, ...previousYears] = clientsWithFields;
                        if (today.diff(moment(lastYearUploaded.created_at), 'years', true) >= 1) {
                            lastYearUploaded.canReplaceFields = false;
                        }
                        _.forEach(previousYears, (client) => {
                            client.canReplaceFields = false;
                        });
                    }
                });

                this.groupedClients = groupedClients;

                // Compute the total area for each row
                this.clients = this.sortDefault(
                    _.map(this.groupedClients, (clientsList: Client[]) => ({
                        ..._.head(clientsList),
                        total_area: _.sumBy(clientsList, 'total_area'),
                    })),
                );

                this.totalArea = _.sumBy(this.clients, 'total_area');

                // Clear search bar
                this.clearSearchBar();
                this.displayedClients = this.clients;
            },
        );

        this.clientsService.CurrentClient.pipe(
            filter((currentClient: Client) => !!currentClient),
            takeUntilDestroyed(this.destroyRef),
        ).subscribe((currentClient: Client) => {
            this.currentClientId = currentClient.id;
        });

        this.layerService
            .getFieldsCropTypeStats()
            .pipe(take(1))
            .subscribe(
                (
                    stats: [
                        {
                            apia_crop_type: number;
                            computed_area__sum: number;
                        },
                    ],
                ) => {
                    const statsWithCropTypes = _.map(stats, (item) => ({
                        cropType: this.layerService.getCropType(item.apia_crop_type),
                        area: item.computed_area__sum,
                    }));

                    const uniqueByName = {};

                    _.forEach(statsWithCropTypes, (item) => {
                        if (uniqueByName[item.cropType.name]) {
                            uniqueByName[item.cropType.name].push(item.area);
                        } else {
                            uniqueByName[item.cropType.name] = [item.area];
                        }
                    });

                    const totalArea = _.sumBy(stats, (item) => item.computed_area__sum);

                    const fieldStats: Stats = {
                        cropsStats: _(statsWithCropTypes)
                            .uniqBy('cropType.name')
                            .map((item) => {
                                const area: number = _.sum(uniqueByName[item.cropType.name]);
                                const percentage = (item.area * 100) / totalArea;
                                return {
                                    cropType: item.cropType,
                                    area,
                                    width: percentage,
                                    percentage,
                                };
                            })
                            .orderBy('area', 'desc')
                            .value(),
                    };

                    fieldStats.cropsStats.forEach((entry, index, array) => {
                        if (index > 0) {
                            entry.width += array[index - 1].width;
                        }
                    });

                    fieldStats.cropsStats = _.orderBy(fieldStats.cropsStats, 'width', 'desc');

                    if (!_.isEmpty(fieldStats.cropsStats)) {
                        this.fieldStats = fieldStats;
                    }
                },
                (error) => {
                    console.error(error);
                },
            );
    }

    getClients = (): any => this.clientsService.getClients().pipe(take(1)).subscribe();

    onModalOpen = (): void => {
        this.client = null;
        this.isAddingModalVisible = true;
    };

    cancelEditingModal = (): void => {
        this.isEditingModalVisible = false;
    };

    editClient = (clientId: number): void => {
        this.client = _.find(this.allSanitizedClients, (c) => c.id === clientId);
        this.isEditingModalVisible = true;
    };

    updateClient = (): void => {
        if (this.clientForm.form.status !== 'VALID') {
            this.displayMessage('warning', 'Formularul are câmpuri invalide');
            return;
        }

        this.isSaving = true;

        const { companyFiscalCode, companyName } = this.clientForm.form.getRawValue();

        this.clientsService
            .updateClient(
                {
                    fiscal_number: companyFiscalCode,
                    name: companyName,
                },
                this.client.id,
            )
            .pipe(take(1))
            .subscribe(
                (client: Client) => {
                    if (this.isLoggingEnabled) console.log('[ClientsList] updateClient', client);

                    this.getClients();

                    /**
                     * If the updated client is the current client, replace it
                     */
                    const currentClient: Client = this.clientsService.CurrentClient.getValue();
                    if (currentClient && currentClient.id === client.id) {
                        this.clientsService.setCurrentClient(client);
                    }

                    this.isEditingModalVisible = false;
                    this.isSaving = false;
                    this.clearSearchBar();

                    this.displayMessage('success', 'Clientul a fost editat cu succes');
                },
                (error) => {
                    console.error(error);

                    this.isEditingModalVisible = false;
                    this.isSaving = false;
                    this.clearSearchBar();

                    this.displayMessage('error', 'A apărut o eroare la editare');
                },
            );
    };

    createClient = (): void => {
        if (this.clientForm.form.status !== 'VALID') {
            this.displayMessage('warning', 'Formularul are câmpuri invalide');
            return;
        }

        this.isSaving = true;

        const { companyFiscalCode, companyName } = this.clientForm.form.getRawValue();

        this.clientsService
            .createClient(companyFiscalCode, companyName)
            .pipe(take(1))
            .subscribe(
                (client: Client) => {
                    if (this.isLoggingEnabled) console.log('[ClientsList] createClient', client);

                    /**
                     * If this is the only created client, set it as current
                     */
                    if (!this.clientsService.CurrentClient.getValue()) {
                        this.clientsService.setCurrentClient(client);
                    }

                    this.isAddingModalVisible = false;
                    this.isSaving = false;
                    this.clearSearchBar();

                    this.displayMessage('success', 'Clientul a fost creat cu succes');
                },
                (error) => {
                    console.error(error);

                    this.isAddingModalVisible = false;
                    this.isSaving = false;
                    this.clearSearchBar();

                    this.displayMessage('error', 'A apărut o eroare la salvare');
                },
            );
    };

    uploadFields = (clientId: number): void => {
        const client: Client = _.find(this.allSanitizedClients, (c) => c.id === clientId);
        if (this.isLoggingEnabled) console.log('Uploading fields for', client);

        this.clientsService.setCurrentClient(client);
        this.router.navigateByUrl('/incarcare-terenuri');
    };

    displayDeleteConfirmation = (clientId: number): void => {
        const victim: Client = _.find(this.allSanitizedClients, (c) => c.id === clientId);

        this.modal.confirm({
            nzTitle: 'Ești sigur că vrei să ștergi următorul client?',
            nzContent: `${victim.display_name} (C.U.I. ${victim.fiscal_number})`,
            nzOkText: 'Șterge',
            nzCancelText: 'Anulează',
            nzOnOk: () =>
                this.clientsService
                    .deleteClient(clientId)
                    .pipe(
                        catchError(this.errorHandler),
                        delay(100),
                        tap((result: any) => {
                            if (this.isLoggingEnabled)
                                console.log('[ClientsList] deleteClient', result);
                            this.clearSearchBar();

                            this.displayMessage('success', 'Clientul a fost șters');
                        }),
                    )
                    .toPromise(),
        });
    };

    sort(sort: { key: string; value: string }): void {
        const { key, value } = sort;

        switch (value) {
            case 'ascend':
                this.displayedClients = _.orderBy(this.displayedClients, key, 'asc');
                this.clients = _.orderBy(this.clients, key, 'asc');
                break;

            case 'descend':
                this.displayedClients = _.orderBy(this.displayedClients, key, 'desc');
                this.clients = _.orderBy(this.clients, key, 'desc');
                break;

            default:
                this.displayedClients = this.sortDefault(this.displayedClients);
                this.clients = this.sortDefault(this.clients);
                break;
        }
    }

    sortDefault = (clients: Client[]): Client[] => {
        return _.orderBy(clients, 'id', 'desc');
    };

    onTextChanged = (value: string): void => {
        if (!value) {
            this.displayedClients = this.clients;
        } else {
            this.displayedClients = this.clients.filter((client) => {
                return (
                    removeDiacritics(client.name).includes(removeDiacritics(value)) ||
                    client.fiscal_number.includes(value)
                );
            });
        }
    };

    toggleRow = (id: number) => (this.expandedData[id] = !this.expandedData[id]);

    requestVegetationReport = (id: number) => {
        this.clientsService
            .requestClientVegetationReport(id)
            .pipe(take(1))
            .subscribe(
                (res) => {
                    this.displayMessage('success', 'Raportul de vegetație a fost solicitat.');
                },
                (error) => {
                    let errorMessage = 'Solicitarea nu a reușit. Te rugăm să încerci mai târziu.';
                    if (error) {
                        if (error.error) {
                            if (error.error.detail) {
                                errorMessage = error.error.detail;
                            }
                        }
                    }
                    this.displayMessage('error', errorMessage);
                },
            );
    };

    private clearSearchBar = (): void => {
        this.onTextChanged(null);
        this.searchValue = null;
    };

    private displayMessage = (type: string, message: string): any =>
        this.message.create(type, message);

    private errorHandler = (errorResponse: Response): Observable<never> => {
        console.error('[ClientsList] Error Handler');
        console.error(errorResponse);
        this.displayMessage('error', 'A apărut o eroare');

        return throwError(errorResponse || 'API Error');
    };
}
