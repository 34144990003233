import {
    AfterContentInit,
    Component,
    DestroyRef,
    ElementRef,
    Input,
    OnInit,
    ViewChild,
    inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClientsService } from '@modules/clients/services/clients.service';
import { AnafCompanyDetails, Client } from '@shared/models/client';
import _ from 'lodash';
import { of, throwError } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

enum FormValidationStatus {
    SUCCESS = 'success',
    VALIDATING = 'validating',
    ERROR = 'error',
}

@Component({
    selector: 'clients-form',
    templateUrl: './client-form.component.html',
    styleUrls: ['./client-form.component.less'],
})
export class ClientFormComponent implements OnInit, AfterContentInit {
    destroyRef = inject(DestroyRef);

    @ViewChild('input') inputElement: ElementRef;

    @Input()
    client: Client;

    form: UntypedFormGroup;

    fiscalCodeValidationStatus: string = null;
    FormValidationStatus = FormValidationStatus;

    matchingClientName: string;

    private clients: Client[];

    constructor(
        private formBuilder: UntypedFormBuilder,
        private clientsService: ClientsService,
    ) {
        this.form = this.formBuilder.group({
            companyFiscalCode: [
                null,
                {
                    updateOn: 'blur',
                    validators: [Validators.required],
                },
            ],
            companyName: [
                null,
                {
                    updateOn: 'change',
                    validators: [Validators.required],
                },
            ],
        });
    }

    ngOnInit() {
        this.clients = this.clientsService.Clients.getValue();

        if (this.client) {
            this.form.get('companyFiscalCode').setValue(this.client.fiscal_number);
            this.form.get('companyName').setValue(this.client.display_name);
            this.matchWithClients(this.client.fiscal_number);
        }

        // Obtain the company's name using the fiscal code
        this.form
            .get('companyFiscalCode')
            .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((fiscalCode) => {
                this.matchWithClients(fiscalCode);

                if (fiscalCode && this.clientsService.isFiscalCodeValid(fiscalCode)) {
                    this.searchCompany(fiscalCode);
                }
            });
    }

    ngAfterContentInit(): void {
        setTimeout(() => this.inputElement.nativeElement.focus(), 100);
    }

    private matchWithClients = (fiscalCode: string): void => {
        const matchingClients: Client[] = _.filter(
            this.clients,
            (client) => client.fiscal_number === fiscalCode,
        );

        if (!_.isEmpty(matchingClients)) {
            this.matchingClientName = _.head(matchingClients).name;
        } else {
            this.matchingClientName = null;
        }
    };

    /**
     * Use ANAF webservice to look up company information
     */
    private searchCompany(value: string) {
        this.fiscalCodeValidationStatus = FormValidationStatus.VALIDATING;
        this.form.get('companyName').disable();

        this.clientsService
            .getAnafCompanyDetails(value)
            .pipe(
                take(1),
                switchMap((company: any) =>
                    company.date_generale.cui ? of(company) : throwError('Invalid company'),
                ),
            )
            .subscribe(
                (company) => {
                    this.handleAnafResponse(company);
                    this.fiscalCodeValidationStatus = FormValidationStatus.SUCCESS;
                    this.form.get('companyName').enable();
                },
                (error) => {
                    console.error(error);
                    this.fiscalCodeValidationStatus = FormValidationStatus.ERROR;
                    this.form.get('companyName').enable();
                },
            );
    }

    private handleAnafResponse(company: AnafCompanyDetails) {
        // Fixing the fiscal code
        this.form
            .get('companyFiscalCode')
            .setValue(
                `${company.inregistrare_scop_Tva.scpTVA ? 'RO' : ''}${company.date_generale.cui}`,
                {
                    emitEvent: false,
                },
            );

        // Setting the company name
        this.form.get('companyName').setValue(company.date_generale.denumire);
    }
}
