<form nz-form [formGroup]="form">
    <nz-form-item>
        <nz-form-label [nzSpan]="8" nzRequired>Cod fiscal</nz-form-label>
        <nz-form-control
            [nzSpan]="16"
            nzHasFeedback
            [nzErrorTip]="mixedTemplate"
            [nzValidateStatus]="fiscalCodeValidationStatus"
        >
            <input
                nz-input
                id="companyFiscalCode"
                name="companyFiscalCode"
                formControlName="companyFiscalCode"
                placeholder="Cod fiscal"
                spellcheck="false"
                maxlength="12"
                required
                #input
            />

            <ng-template #mixedTemplate let-control>
                <ng-container *ngIf="control.hasError('required')">Câmp necesar</ng-container>
            </ng-template>
        </nz-form-control>
    </nz-form-item>

    <nz-form-item class="Last-Form-Item">
        <nz-form-label [nzSpan]="8" nzRequired>Denumire companie</nz-form-label>
        <nz-form-control [nzSpan]="16" nzHasFeedback [nzErrorTip]="mixedTemplate">
            <input
                nz-input
                id="companyName"
                name="companyName"
                formControlName="companyName"
                placeholder="Denumire"
                spellcheck="false"
                maxlength="128"
                required
            />

            <ng-template #mixedTemplate let-control>
                <ng-container *ngIf="control.hasError('maxlength')">Introduceți maxim 128 de caractere</ng-container>
                <ng-container *ngIf="control.hasError('required')">Câmp necesar</ng-container>
            </ng-template>
        </nz-form-control>
    </nz-form-item>

    <div class="Message-Box" *ngIf="form.get('companyFiscalCode').value">
        <shared-message-box
            [subtext]="matchingClientName ? 'Clientul se încadrează în următorul grup' : null"
            [text]="matchingClientName || 'Clientul nu se încadrează în niciun grup'"
            type="info"
        ></shared-message-box>
    </div>
</form>
