<nz-modal
    nzWrapClassName="vertical-center-modal"
    [(nzVisible)]="isAddingModalVisible"
    nzTitle="Adaugă un client"
    (nzOnCancel)="isAddingModalVisible = false"
    [nzContent]="modalContent"
    [nzFooter]="addingModalFooter"
>
</nz-modal>

<nz-modal
    nzWrapClassName="vertical-center-modal"
    [(nzVisible)]="isEditingModalVisible"
    nzTitle="Editare client"
    (nzOnCancel)="cancelEditingModal()"
    [nzContent]="modalContent"
    [nzFooter]="editingModalFooter"
>
</nz-modal>

<ng-template #modalContent>
    <clients-form [client]="client" #clientForm></clients-form>
</ng-template>

<ng-template #addingModalFooter>
    <button nz-button nzType="default" (click)="isAddingModalVisible = false">Anulează</button>
    <button nz-button nzType="primary" (click)="createClient()" [nzLoading]="isSaving">
        Salvează
    </button>
</ng-template>

<ng-template #editingModalFooter>
    <button nz-button nzType="default" (click)="cancelEditingModal()">Anulează</button>
    <button nz-button nzType="primary" (click)="updateClient()" [nzLoading]="isSaving">
        Salvează
    </button>
</ng-template>

<core-header></core-header>

<div class="Content-Container Centered-Content">
    <div *ngIf="fieldStats" class="Card Top-Row">
        <div class="Field-Stats-Container">
            <fields-stats [fieldStats]="fieldStats" setCompactMode></fields-stats>
        </div>
    </div>

    <div class="Top-Row">
        <button
            nz-button
            (click)="onModalOpen()"
            [disabled]="!permissions.includes('core.add_client')"
        >
            <div class="flex flex-row items-center">
                <span class="mr-1" nz-icon nzType="plus"></span>
                <span>Adaugă</span>
            </div>
        </button>

        <div class="Search-Bar-Container">
            <nz-input-group [nzSuffix]="suffixTemplate" [nzPrefix]="prefixTemplate" nzSize="medium">
                <input
                    nzSize="medium"
                    type="text"
                    nz-input
                    [(ngModel)]="searchValue"
                    (ngModelChange)="onSearchChange($event)"
                    placeholder="Caută"
                />
            </nz-input-group>

            <ng-template #prefixTemplate><i nz-icon nzType="search"></i></ng-template>

            <ng-template #suffixTemplate
                ><i
                    nz-icon
                    nz-tooltip
                    class="ant-input-clear-icon"
                    nzTheme="fill"
                    nzType="close-circle"
                    *ngIf="searchValue"
                    (click)="searchValue = null; onSearchChange(null)"
                ></i
            ></ng-template>
        </div>
    </div>

    <nz-table
        class="Table"
        #table
        [nzData]="displayedClients"
        [nzTitle]="tableTitle"
        [nzBordered]="true"
        [nzSize]="'small'"
        [nzNoResult]="noResult"
        [nzPageSize]="30"
        [nzShowPagination]="true"
        [nzFrontPagination]="true"
    >
        <thead (nzSortChange)="sort($event)" nzSingleSort>
            <tr>
                <th
                    *ngFor="let column of columns"
                    [nzShowSort]="column?.isSortable"
                    [nzSortOrder]="column?.sortOrder"
                    [nzSortFn]="column?.sortFn"
                    [nzWidth]="column?.width"
                >
                    {{ column.title }}
                </th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let data of table.data">
                <tr class="Cursor-Pointer">
                    <td (click)="toggleRow(data.id)" [class.Active]="data.id === currentClientId">
                        <div class="Weight-500">{{ data?.display_name }}</div>
                    </td>
                    <td (click)="toggleRow(data.id)">
                        {{ data?.total_area | number: '1.0-2' }} ha
                    </td>
                    <td (click)="toggleRow(data.id)">{{ data?.fiscal_number }}</td>
                    <td (click)="toggleRow(data.id)"></td>
                    <td [nzAlign]="'center'"></td>
                </tr>

                <ng-container *ngIf="expandedData[data.id]">
                    <tr
                        class="Expanded-Row"
                        *ngFor="let entry of groupedClients[data.fiscal_number]"
                    >
                        <td>{{ entry?.agriculture_year || '—' }}</td>
                        <td>{{ entry?.total_area | number: '1.0-2' }} ha</td>
                        <td></td>
                        <td>{{ entry?.moment | date: 'dd MMM y, HH:mm' }}</td>
                        <td [nzAlign]="'center'" class="No-Padding">
                            <button
                                class="Action-Button"
                                nz-button
                                nzType="default"
                                nzShape="circle"
                                nzSize="small"
                                [disabled]="!canModifyFields || !entry.canReplaceFields"
                                nz-popconfirm
                                nzPopconfirmTitle="Sunteți sigur că vreți să înlocuiți terenurile? Acest proces este ireversibil."
                                nzPopconfirmPlacement="bottom"
                                (nzOnConfirm)="uploadFields(entry.id)"
                                [nzCondition]="!entry?.agriculture_year"
                            >
                                <i nz-icon nzType="upload"></i>
                            </button>

                            <button
                                class="Action-Button"
                                (click)="editClient(entry.id)"
                                nz-button
                                nzType="default"
                                nzShape="circle"
                                nzSize="small"
                                [disabled]="!permissions.includes('core.change_client')"
                            >
                                <i nz-icon nzType="edit"></i>
                            </button>

                            <button
                                class="Action-Button"
                                nz-button
                                nzType="default"
                                nzShape="circle"
                                nzSize="small"
                                nz-dropdown
                                [nzDropdownMenu]="moremenu"
                            >
                                <i nz-icon nzType="more"></i>
                            </button>
                            <nz-dropdown-menu #moremenu="nzDropdownMenu">
                                <ul nz-menu nzSelectable>
                                    <li nz-menu-item (click)="requestVegetationReport(entry.id)">
                                        Solicită raport vegetație
                                    </li>
                                </ul>
                            </nz-dropdown-menu>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
        </tbody>
    </nz-table>

    <ng-template #tableTitle>
        <div class="Total-Area-Container p-1">
            <span class="text-gray-500">Total suprafață încărcată: </span>
            <span class="Weight-500">{{ totalArea | number: '1.0-2' }} ha</span>
        </div>
    </ng-template>

    <ng-template #noResult>
        <nz-empty [nzNotFoundContent]="clients.length > 0 ? '' : 'Nu există clienți'"></nz-empty>
    </ng-template>
</div>
