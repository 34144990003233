<div class="Title">Clienți</div>

<div class="Search-Bar-Container">
    <nz-input-group [nzSuffix]="suffixTemplate" [nzPrefix]="prefixTemplate" nzSize="small">
        <input
            type="text"
            nz-input
            [(ngModel)]="searchValue"
            (ngModelChange)="onTextChanged($event)"
            placeholder="Caută"
        />
    </nz-input-group>

    <ng-template #prefixTemplate><i nz-icon nzType="search"></i></ng-template>

    <ng-template #suffixTemplate
        ><i
            nz-icon
            nz-tooltip
            class="ant-input-clear-icon"
            nzTheme="fill"
            nzType="close-circle"
            *ngIf="searchValue"
            (click)="searchValue = null; onTextChanged(null)"
        ></i
    ></ng-template>
</div>

<div class="Clients-List">
    <nz-collapse [nzBordered]="false" nzAccordion>
        <nz-collapse-panel
            *ngFor="let client of filteredClients"
            (click)="onPanelClick(client)"
            [class.Active]="client.fiscal_number === currentClient?.fiscal_number"
            [nzHeader]="client.name"
            [nzActive]="client.active"
            [nzShowArrow]="false"
            [ngStyle]="panelStyle"
        >
            <div
                class="Entry"
                (click)="switchClient(entry)"
                *ngFor="let entry of groupedClients[client.fiscal_number]"
                [class.Active-Class]="entry.id === currentClient?.id"
            >
                {{ entry?.agriculture_year || '—' }}
            </div>
        </nz-collapse-panel>
    </nz-collapse>

    <div *ngIf="filteredClients.length == 0" class="Empty-Clients">Nu există clienți</div>
</div>
