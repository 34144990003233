import { Component, OnInit } from '@angular/core';
import { AuthService } from '@modules/auth/services/auth.service';

@Component({
    selector: 'app-clients',
    templateUrl: './clients.component.html',
    styleUrls: ['./clients.component.less']
})
export class ClientsComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
