import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ClientsService } from '@modules/clients/services/clients.service';
import { Client } from '@shared/models/client';
import { removeDiacritics } from '@shared/utils/utils';
import _ from 'lodash';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'clients-switch',
    templateUrl: './clients-switch.component.html',
    styleUrls: ['./clients-switch.component.less'],
})
export class ClientsSwitchComponent implements OnInit {
    destroyRef = inject(DestroyRef);

    clients: Client[];
    filteredClients: Client[];
    groupedClients: _.Dictionary<Client[]>;

    currentClient: Client;

    searchValue: string | null;
    panelStyle = {
        'font-size': '13px',
        'border-bottom': 0,
    };

    constructor(private clientsService: ClientsService) {}

    ngOnInit() {
        this.clientsService.CurrentClient.pipe(
            filter((currentClient: Client) => !!currentClient),
            takeUntilDestroyed(this.destroyRef),
        ).subscribe((currentClient: Client) => {
            this.currentClient = currentClient;
            this.filteredClients = _(this.filteredClients)
                .map((client) => ({
                    ...client,
                    active: client.fiscal_number === this.currentClient.fiscal_number,
                }))
                .value();
        });

        this.clientsService.Clients.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
            (clients: Client[]) => {
                this.clients = _(clients)
                    .uniqBy('fiscal_number')
                    .orderBy('name', 'asc')
                    .map((client) => ({
                        ...client,
                        active: client.fiscal_number === this.currentClient.fiscal_number,
                    }))
                    .value();

                this.groupedClients = _(clients)
                    .orderBy(['moment'], ['desc'])
                    .groupBy('fiscal_number')
                    .value();

                // Clear search bar
                this.onTextChanged(null);
                this.searchValue = null;
                this.filteredClients = this.clients;
            },
        );
    }

    onPanelClick = (client: Client): void => {
        const children: Client[] = this.groupedClients[client.fiscal_number];
        if (_.size(children) === 1) {
            this.switchClient(_.head(children));
        }
    };

    switchClient = (client: Client): void => {
        /**
         * Restrict switching to the same client, but only if a current one already exists
         */
        if ((this.currentClient && client.id !== this.currentClient.id) || !this.currentClient) {
            this.clientsService.setCurrentClient(client);
        }
    };

    onTextChanged = (value: string): void => {
        if (!value) {
            this.filteredClients = this.clients;
        } else {
            this.filteredClients = this.clients.filter((client) => {
                return removeDiacritics(client.name).includes(removeDiacritics(value));
            });
        }
    };
}
