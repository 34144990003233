import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientsRoutingModule } from './clients-routing.module';
import { ClientsComponent } from './components/clients/clients.component';
import { ClientsListComponent } from './components/clients-list/clients-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@core/core.module';
import { ClientsSwitchComponent } from './components/clients-switch/clients-switch.component';
import { FieldsModule } from '@modules/fields/fields.module';
import { ClientFormComponent } from './components/client-form/client-form.component';
import { SharedModule } from '@shared/shared.module';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
    declarations: [ClientsComponent, ClientsListComponent, ClientsSwitchComponent, ClientFormComponent],
    imports: [
        CommonModule,
        ClientsRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        FieldsModule,
        SharedModule,
        NzEmptyModule,
        NzButtonModule,
        NzTableModule,
        NzCollapseModule,
        NzInputModule,
        NzDropDownModule,
        NzModalModule,
        NzFormModule,
        NzPopconfirmModule,
        NzMessageModule,
        NzIconModule,
    ],
    exports: [ClientsSwitchComponent],
})
export class ClientsModule {}
